import { clsx } from 'clsx'
import { type FC, type JSX, type ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { StaticImage } from '../../../image'
import { urlResolverGetShopApothekeApp } from '../../../url-handling'

import IllustrationInsuranceCard from './illustration-prescription-card.png'
import { ProductPrescriptionDialogCardTitleWithImage } from './ProductPrescriptionDialogCardTitleWithImage'

const accentuate = (text: ReactNode[]): JSX.Element => (
  <span className="font-bold italic text-dark-accent">{text}</span>
)

const paragraph = (text: ReactNode[]): JSX.Element => (
  <p className="text-s text-dark-primary-strong">{text}</p>
)

export const ProductPrescriptionDialogInsuranceCard: FC<{
  className: string
}> = ({ className }) => {
  const intl = useIntl()
  const config = useGlobalConfigContext()
  const insuranceCardImageAlt = intl.formatMessage({
    id: 'rxDifferenceModal.insuranceCard.title',
  })

  return (
    <div
      className={clsx(
        className,
        'rounded-lg bg-light-primary-low p-6 tablet:flex tablet:flex-row tablet:gap-4',
      )}
    >
      <div>
        <StaticImage
          alt={insuranceCardImageAlt}
          className="hidden max-w-[140px] tablet:block"
          src={IllustrationInsuranceCard}
        />
      </div>
      <div className="flex flex-col gap-4">
        <ProductPrescriptionDialogCardTitleWithImage
          alt={insuranceCardImageAlt}
          src={IllustrationInsuranceCard}
          title={intl.formatMessage({
            id: 'rxDifferenceModal.insuranceCard.title',
          })}
        />
        <FormattedMessage
          id="prescriptionDialog.insuranceCard.instructions"
          values={{
            em: accentuate,
            // eslint-disable-next-line id-length
            p: paragraph,
          }}
        />

        <Button<'a'>
          className="bg-light-brand-medium text-dark-brand"
          href={urlResolverGetShopApothekeApp(config)}
          slots={{ root: 'a' }}
        >
          <FormattedMessage id="prescriptionDialog.insuranceCard.findOutMore" />
        </Button>
      </div>
    </div>
  )
}
