'use client'

import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogContent, DialogTitle } from 'base-ui'

import { ProductPrescriptionDialogEprescriptionCard } from './ProductPrescriptionDialogEprescriptionCard'
import { ProductPrescriptionDialogInsuranceCard } from './ProductPrescriptionDialogInsuranceCard'
import { ProductPrescriptionDialogRegularPrescriptionCard } from './ProductPrescriptionDialogRegularPrescriptionCard'

export const ProductPrescriptionDialog: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle onClose={onClose}>
        <FormattedMessage id="rezeptBestellung.modal.title" />
      </DialogTitle>
      <DialogContent className="flex flex-col gap-4">
        <p className="text-s font-medium">
          <FormattedMessage id="rezeptBestellung.modal.introduction" />
        </p>
        <ProductPrescriptionDialogInsuranceCard className="my-0" />
        <ProductPrescriptionDialogEprescriptionCard className="my-0" />
        <ProductPrescriptionDialogRegularPrescriptionCard className="my-0" />
      </DialogContent>
    </Dialog>
  )
}
