'use client'

import dynamic from 'next/dynamic'

import { Dialog } from 'base-ui'

import { Loading } from '../../../loading'

export const ProductPrescriptionDynamicDialog = dynamic(
  async () =>
    import('./ProductPrescriptionDialog').then(
      (mod) => mod.ProductPrescriptionDialog,
    ),
  {
    loading: () => (
      <Dialog open>
        <Loading />
      </Dialog>
    ),
    ssr: false,
  },
)
