import { type StaticImageData } from 'next/image'
import { type FC } from 'react'

import { StaticImage } from '../../../image'

export const ProductPrescriptionDialogCardTitleWithImage: FC<{
  alt: string
  src: StaticImageData
  title: string
}> = ({ alt, src, title }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <StaticImage
        alt={alt}
        className="max-w-[140px] tablet:hidden"
        src={src}
      />
      <p className="text-m font-medium">{title}</p>
    </div>
  )
}
