import { clsx } from 'clsx'
import { type FC, type ReactElement, type ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { StaticImage } from '../../../image'
import { urlResolverGetRedeemEprescription } from '../../../url-handling'

import IllustrationEPrescription from './illustration-eprescription.png'
import { ProductPrescriptionDialogCardTitleWithImage } from './ProductPrescriptionDialogCardTitleWithImage'

const ePrescriptionInstructions = (text: ReactNode[]): ReactElement => (
  <p className="text-s text-dark-primary-strong">{text}</p>
)

export const ProductPrescriptionDialogEprescriptionCard: FC<{
  className: string
}> = ({ className }) => {
  const intl = useIntl()
  const config = useGlobalConfigContext()
  const eprescriptionImageAlt = intl.formatMessage({
    id: 'rxDifferenceModal.ePrescription.title',
  })

  return (
    <div
      className={clsx(
        className,
        'rounded-lg bg-light-primary-low p-6 tablet:flex tablet:flex-row tablet:gap-4',
      )}
    >
      <div>
        <StaticImage
          alt={eprescriptionImageAlt}
          className="hidden max-w-[140px] tablet:block"
          src={IllustrationEPrescription}
        />
      </div>
      <div className="flex flex-col gap-4">
        <ProductPrescriptionDialogCardTitleWithImage
          alt={eprescriptionImageAlt}
          src={IllustrationEPrescription}
          title={intl.formatMessage({
            id: 'rxDifferenceModal.ePrescription.title',
          })}
        />
        <FormattedMessage
          id="prescriptionDialog.ePrescriptionInstructions"
          values={{
            // eslint-disable-next-line id-length
            p: ePrescriptionInstructions,
          }}
        />
        <Button<'a'>
          className="bg-light-brand-medium text-dark-brand"
          href={urlResolverGetRedeemEprescription(config)}
          slots={{ root: 'a' }}
        >
          <FormattedMessage id="prescriptionDialog.activateEPrescription" />
        </Button>
      </div>
    </div>
  )
}
