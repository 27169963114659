import { clsx } from 'clsx'
import { type FC, type ReactElement, type ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from 'base-ui'

import { useExperimentalAddStaticFileHost } from '../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { StaticImage } from '../../../image'
import { urlResolverGetFreeEnvelopePdf } from '../../../url-handling'

import IllustrationRegularPrescription from './illustration-regular-prescription.png'
import { ProductPrescriptionDialogCardTitleWithImage } from './ProductPrescriptionDialogCardTitleWithImage'

const regularPrescriptionInstructionsEmText = (
  text: ReactNode,
): ReactElement => (
  <span className="font-bold italic text-dark-accent">{text}</span>
)
const regularPrescriptionInstructionsText = (text: ReactNode): ReactElement => (
  <p className="text-s text-dark-primary-strong">{text}</p>
)
export const ProductPrescriptionDialogRegularPrescriptionCard: FC<{
  className: string
}> = ({ className }) => {
  const intl = useIntl()
  const prescriptionImageAlt = intl.formatMessage({
    id: 'productPrescriptionModal.regularPrescription',
  })
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  return (
    <div
      className={clsx(
        className,
        'rounded-lg bg-light-primary-low p-6 tablet:flex tablet:flex-row tablet:gap-4',
      )}
    >
      <div>
        <StaticImage
          alt={prescriptionImageAlt}
          className="hidden max-w-[140px] tablet:block"
          src={IllustrationRegularPrescription}
        />
      </div>
      <div className="flex flex-col gap-4">
        <ProductPrescriptionDialogCardTitleWithImage
          alt={prescriptionImageAlt}
          src={IllustrationRegularPrescription}
          title={intl.formatMessage({
            id: 'productPrescriptionModal.regularPrescription',
          })}
        />
        <FormattedMessage
          id="prescriptionDialog.regularPrescriptionInstructions"
          values={{
            em: regularPrescriptionInstructionsEmText,
            // eslint-disable-next-line id-length
            p: regularPrescriptionInstructionsText,
          }}
        />
        <Button<'a'>
          className="bg-light-brand-medium text-dark-brand"
          href={addStaticCdnHostToUrl(urlResolverGetFreeEnvelopePdf())}
          slots={{ root: 'a' }}
          target="_blank"
        >
          <FormattedMessage id="prescriptionDialog.downloadFreeEnvelope" />
        </Button>
      </div>
    </div>
  )
}
